// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteAlert, KiteButton, KiteCard } from '@kite/react-kite';
import { LinearProgressStepper } from '@kite/react-kite-plus';
import {
  BandwidthOptions,
  CustomDropdown,
  CustomSwitch,
  QuantityCard,
  DropdownInput,
} from 'components';

// Hooks
import { useDisabledCheck, useQueryDataContext, useScrollToTop } from 'hooks';

// Utils
import { getRecommended, networkEdgeStepperValues } from 'utils';

// Types
import { TDisableConfigFamily } from 'types';

// Styles
import './NetworkEdgeContainer.scss';
import {
  rcSeats,
  enterpriseInternetIds,
} from 'utils/defaultsAndConstants/constants';

export interface INetworkEdgeContainerProps {
  className?: string;
  networkCategory: 'MNE' | 'ENE';
}

/** Network Edge page with stepper */

const NetworkEdgeContainer = ({
  className = '',
  networkCategory,
}: INetworkEdgeContainerProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [stepNumber, setStepNumber] = useState(0);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasDeviceManagementError, setHasDeviceManagementError] =
    useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, stepNumber, refNestLevel: 2 });

  const { handleDisabledCheck } = useDisabledCheck();
  const { allProductFamilies, allProducts, currentSelections } =
    useQueryDataContext();

  const otherNetworkCategory = networkCategory === 'MNE' ? 'ENE' : 'MNE';

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const { steps, stepperValues } = useMemo(() => {
    const stepperValues = networkEdgeStepperValues[networkCategory].filter(
      (step) => {
        if (step.label === 'Network') return true;
        // Removing steps where no products are published
        return allProductFamilies.find((family) => {
          return (
            family.name.includes(step.label) &&
            family.category === networkCategory &&
            family.published
          );
        });
      }
    );
    const steps = stepperValues.reduce((acc: string[], curr) => {
      acc.push(curr.label);
      return acc;
    }, []);

    return { steps, stepperValues };
  }, [networkCategory, allProductFamilies]);

  const {
    productFamilies,
    networkFamily,
    networkProduct,
    selectionOptions,
    deviceManagementFamily,
  } = useMemo(() => {
    const productFamilies = allProductFamilies?.filter((prod) =>
      prod.category.includes(networkCategory)
    );

    const networkFamily = productFamilies.find((f) =>
      f.name.includes('Network Speed')
    );

    const networkProduct = currentSelections?.find(
      (p) => p.familyId === networkFamily?.id
    );

    const deviceManagementFamily = productFamilies.find((f) =>
      f.name.includes('Device Management')
    );

    const selectionOptions =
      networkFamily?.products.reduce((acc: number[], curr) => {
        let speedAsNumber;

        if (curr.name.includes('Mbps')) {
          speedAsNumber = parseInt(curr.name.split(' Mbps')[0]);
        } else {
          speedAsNumber = parseInt(curr.name.split(' Gbps')[0]) * 1000;
        }

        acc.push(speedAsNumber);
        return acc;
      }, []) || [];

    return {
      productFamilies,
      networkFamily,
      networkProduct,
      selectionOptions,
      deviceManagementFamily,
    };
  }, [allProductFamilies, currentSelections, networkCategory]);

  const { recommendedBandwidth } = useMemo(() => {
    const { recommended } = getRecommended({
      network: networkFamily,
      selectionOptions,
      allProducts,
      currentSelections,
      allProductFamilies,
    });
    return {
      recommendedBandwidth: recommended,
    };
  }, [
    allProducts,
    allProductFamilies,
    networkFamily,
    currentSelections,
    selectionOptions,
  ]);

  const networkNotes = useMemo(() => {
    const notes = networkFamily?.description.split('; ') || [];

    return notes.map((note, i) => (
      <li key={`${networkFamily?.name} Network Note ${i}`}>{note}</li>
    ));
  }, [networkFamily]);

  const isDisabled = useMemo(() => {
    if (deviceManagementFamily) {
      return handleDisabledCheck({
        family: deviceManagementFamily.name as TDisableConfigFamily,
        selections: currentSelections,
      });
    } else if (networkFamily) {
      return handleDisabledCheck({
        family: networkFamily.name as TDisableConfigFamily,
        selections: currentSelections,
      });
    }
    return true;
  }, [
    currentSelections,
    networkFamily,
    handleDisabledCheck,
    deviceManagementFamily,
  ]);

  const { networkDropdown, otherDropdowns } = useMemo(() => {
    const pageOneFamilies = productFamilies.filter(
      (family) => !family.subcategory && !family.name.includes('Onboarding Fee')
    );

    const networkDropdown = (
      <CustomDropdown productFamily={pageOneFamilies[0]} />
    );
    const otherDropdowns = pageOneFamilies
      .slice(1, pageOneFamilies.length)
      .map((family) => (
        <KiteCard
          key={`NE Dropdown ${family.id}`}
          className="network-edge-container__card"
        >
          {family.type === 'enum' ? (
            <CustomDropdown productFamily={family} />
          ) : family.type === 'boolean-many' ? (
            <CustomSwitch
              key={`${family.name} switch`}
              productFamily={family}
            />
          ) : (
            <DropdownInput productFamily={family} />
          )}
        </KiteCard>
      ));

    return { networkDropdown, otherDropdowns };
  }, [productFamilies]);

  const firstPage = useMemo(() => {
    if (!networkFamily) return null;

    return (
      <div>
        <h2>1. Network</h2>
        <KiteCard className="network-edge-container__card">
          <div className="network-edge-container__recommend-wrapper">
            <p>
              Based on your previous selections, we <b>recommend</b> the
              following:
            </p>
            <p className="network-edge-container__recommend-text">
              {networkCategory} {recommendedBandwidth}
            </p>
          </div>

          {isDisabled && (
            <KiteAlert
              description={`${networkCategory} not available when ${otherNetworkCategory} or Managed WiFi are configured.`}
              className="network-edge-container__alert"
              level="page"
              margin="10px 0"
              type="caution"
              useDark={false}
            />
          )}

          {networkDropdown}

          <p className="network-edge-container__select-recommend-text">
            ({recommendedBandwidth} Recommended)
          </p>

          <ul className="network-edge-container__select-details">
            {networkNotes}
          </ul>

          <BandwidthOptions recommendedBandwidth={recommendedBandwidth} />
        </KiteCard>

        {otherDropdowns}
      </div>
    );
  }, [
    networkFamily,
    networkCategory,
    recommendedBandwidth,
    isDisabled,
    otherNetworkCategory,
    networkDropdown,
    otherDropdowns,
    networkNotes,
  ]);

  const stepperPage = useMemo(() => {
    const currentStep = steps[stepNumber];
    const productFamily = productFamilies.find((family) =>
      family.name.includes(currentStep)
    );
    const cloudFamily = productFamilies.find(
      (family) => family.name === 'MNE Cloud Storage'
    );

    if (!productFamily) return;

    return (
      <div>
        <h2>{`${stepNumber + 1}. ${steps[stepNumber]}`}</h2>
        <QuantityCard
          key={`${currentStep} quantity card`}
          productFamily={productFamily}
        />

        {currentStep === 'Cameras' && cloudFamily && (
          <QuantityCard
            key="Cloud Storage quantity card"
            productFamily={cloudFamily}
          />
        )}
      </div>
    );
  }, [productFamilies, stepNumber, steps]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleErrorCheck = useCallback(() => {
    if (stepNumber === 0 && !networkProduct) {
      setHasError(true);
      return false;
    } else {
      if (stepNumber === 1 || stepNumber === 3) {
        setHasError(true);
      } else {
        setHasError(false);
      }
      return true;
    }
  }, [networkProduct, stepNumber]);

  const enterpriseInternetSelected = useMemo(() => {
    if (
      currentSelections.find((s) => enterpriseInternetIds.includes(s.productId))
    ) {
      return true;
    }
    return false;
  }, [currentSelections]);

  const onNextClick = useCallback(() => {
    const nextStep = stepNumber + 1;
    if (enterpriseInternetSelected && nextStep === 1) {
      setHasError(false);
      setStepNumber(nextStep);
    } else {
      handleErrorCheck() && setStepNumber(nextStep);
    }
  }, [stepNumber, handleErrorCheck, enterpriseInternetSelected]);

  const onPreviousClick = useCallback(() => {
    handleErrorCheck() && setStepNumber(stepNumber - 1);
  }, [stepNumber, handleErrorCheck]);

  const rcSeatsSelected = useMemo(() => {
    if (
      currentSelections.find(
        (s) => rcSeats.includes(s.productId) && s.quantity >= 5
      )
    ) {
      return true;
    }
    return false;
  }, [currentSelections]);

  const onStepClick = useCallback(
    (s: string) => {
      if (steps.indexOf(s) === 3 && rcSeatsSelected) {
        setHasError(false);
        setStepNumber(3);
      } else if (steps.indexOf(s) === 1 && enterpriseInternetSelected) {
        setHasError(false);
        setStepNumber(1);
      } else {
        handleErrorCheck() && setStepNumber(steps.indexOf(s));
      }
    },
    [steps, handleErrorCheck, rcSeatsSelected, enterpriseInternetSelected]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (!networkProduct && enterpriseInternetSelected && stepNumber === 1) {
      setStepNumber(1);
    } else if (!networkProduct && rcSeatsSelected && stepNumber === 3) {
      setStepNumber(3);
    } else if (!networkProduct) {
      setStepNumber(0);
    } else {
      hasError && handleErrorCheck();
    }
  }, [
    networkProduct,
    hasError,
    handleErrorCheck,
    steps.length,
    stepNumber,
    rcSeatsSelected,
    enterpriseInternetSelected,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      ref={ref}
      className={`network-edge-container ${className}__network-edge-container`}
    >
      <KiteCard className="network-edge-container__stepper-wrapper">
        <LinearProgressStepper
          leanLeft
          activeStep={steps[stepNumber]}
          onClick={onStepClick}
          steps={stepperValues}
        />
      </KiteCard>

      {hasError && (
        <KiteAlert
          className="network-edge-container__alert"
          description={`${networkCategory} must have a network speed value to configure products.`}
          level="page"
          margin="10px 0"
          onClose={() => setHasError(false)}
          type="alert"
        />
      )}

      {hasDeviceManagementError && (
        <KiteAlert
          className="network-edge-container__alert"
          description={`${networkCategory} Device Management cannot be configured when ${otherNetworkCategory} or ${otherNetworkCategory} Device Management is configured.`}
          level="page"
          margin="10px 0"
          onClose={() => setHasDeviceManagementError(false)}
          type="alert"
        />
      )}

      {stepNumber === 0 ? firstPage : stepperPage}

      <div className="network-edge-container__button-container">
        <KiteButton
          className={classNames({
            'network-edge-container__prev-button': true,
            'network-edge-container__prev-button--hidden': stepNumber === 0,
          })}
          type="outline"
          onClick={onPreviousClick}
        >
          Previous: {steps[stepNumber - 1]}
        </KiteButton>

        <KiteButton
          className={classNames({
            'network-edge-container__next-button': true,
            'network-edge-container__next-button--hidden':
              stepNumber === steps.length - 1,
          })}
          type="outline"
          onClick={onNextClick}
        >
          Next: {steps[stepNumber + 1]}
        </KiteButton>
      </div>
    </div>
  );
};

export default NetworkEdgeContainer;
